const HandleErrorMessage = (message = "", lang = "es") => {
    let message_ = "";
    switch (message) {
        case "Network request failed": {
            message_ = "Falló la conexión al servidor, verifica tu conexión a internet o comunícate con soporte técnico...";
            break;
        }
        case "Unexpected token < in JSON at position 0": {
            message_ = "Falló el procesamiento de datos, intentalo nuevamente...";
            break;
        }
        default: {
            message_ = message;
            break;
        }
    }
    return message_;
}
export default HandleErrorMessage;
