//libs
import handleErrorMessage from "./_handle_error_message";
import {ErrorException} from "../types";
//types
export type TResolver = {
    method: 'POST' | 'GET' | 'PUT' | 'DELETE';
    headers: TResolverHeaders;
    [x: string]: any;
}

type TResolverHeaders = {
    "Accept"?: string;
    "Content-Type"?: string;
    [x: string]: any;
}

type TResolverFetchData = {
    [x: string]: any;
}

//interfaces
export interface IResolverFetchStatus {
    is_resolve: boolean;
    code: number | 200 | 400 | 500;
    message?: string;
    error?: string;
    data?: TResolverFetchData;

    [x: string]: any;
}

export interface IDestinyHistoryReject {
    code: number,
    error?: string,
}

//module
const ResolverFetch = async (url: string, config?: TResolver, token?: string) => {
    return await new Promise<IResolverFetchStatus>(async (resolve: (value: IResolverFetchStatus) => void, reject: (reason: IDestinyHistoryReject) => void) => {
        try {
            // @ts-ignore
            const response = await fetch(url, config ? config : {
                "method": 'GET',
                "headers": {
                    "Accept": "application/json",
                    "authorization": token
                },
            });
            if (response.status === 200) {
                const res = await response.json();
                const status: IResolverFetchStatus = {
                    is_resolve: true,
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    ...res,
                };
                resolve(status);
            } else {
                const res = await response.json();
                const status: IResolverFetchStatus = {
                    is_resolve: false,
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    ...res,
                };
                resolve(status);
            }
        } catch (error: any | ErrorException) {
            const status: IDestinyHistoryReject = {
                code: 500,
                error: handleErrorMessage(error.message),
            };
            return reject(status);
        }
    });
}
export default ResolverFetch;
