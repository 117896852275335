import React from "react";
//theme
import {Route, Switch} from "react-router-dom";
//libs
//views
import HomeView from "./home";
//const
//component
const IndexSwitch = () => {
    return (
        <Switch>
            <Route exact path="/es*" component={HomeView}/>
            {/*default*/}
            <Route exact path="*" component={HomeView}/>
        </Switch>
    )
}

export default IndexSwitch;
