import React from "react";
//models
import {TravelInterurbanUser} from "../../../../../api/travel_interurban_user/travelInterurbanUserModel";
//libs
import config from "../../../../../config";
import {faUser, faStar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//types
//interface
interface Props {
    travel_interurban_user: TravelInterurbanUser;
}

//Component
const TravelInterurbanUserSharingPanelDriverType2 = (props: Props) => {

    return (
        <div style={{width: '100%', position: 'absolute', zIndex: 10,}}>
            <div
                style={{
                    backgroundColor: 'rgba(3,3,3,0.6)', color: '#fff', padding: '10px 15px'
                }}
            >
                Voy a dejarlo en {props.travel_interurban_user.destiny}
            </div>
            <div
                style={{
                    backgroundColor: 'rgba(3,3,3,0.5)',
                    color: '#fff',
                    padding: '0px 15px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        width: "5rem",
                        height: "5rem",
                        overflow: "hidden",
                        borderRadius: "50%",
                        backgroundColor: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderColor: config.colorPrimary,
                        borderWidth: "4px",
                        borderStyle: "solid",
                        margin: 5
                    }}
                >
                    <img
                        src={props.travel_interurban_user.user_passenger?.url_image}
                        style={{
                            width: "100%", height: "100%", margin: "auto", objectFit: "cover",
                        }}
                        alt=""
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: '10px',
                        textTransform: "capitalize"
                    }}
                >
                    <strong
                        style={{
                            textTransform: "capitalize"
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faUser}
                            style={{
                                marginRight: 5
                            }}
                        />
                        {props.travel_interurban_user.user_passenger?.name.toLowerCase().split(" ", 1)[0]}
                    </strong>
                    <i>
                        Calificación {parseFloat(props.travel_interurban_user.user_passenger?.qualification).toFixed(1)}
                        <FontAwesomeIcon
                            icon={faStar}
                            color={"orange"}
                            style={{
                                marginLeft: 5
                            }}
                        />
                    </i>
                </div>
            </div>
        </div>
    )
}
export default TravelInterurbanUserSharingPanelDriverType2;
