const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://torisimo.dvgeo.app' : 'http://192.168.1.106:3044',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://torisimo.dvgeo.app' : 'http://192.168.1.106:3044',
    api: 'api/',
    apiSocket: 'torisimo::1.2',
    nameDir: 'torisimo',
    package: 'app.dvgeo.torisimo.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyAr42n4YWi7HH-JiJ5emRwopWnPE_hE4g4',
    appName: 'Móvil Torísimo',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#FFB917',
    colorDark: '#B90000',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.torisimo.passenger',
    playStoreDriverId: 'app.dvgeo.torisimo.driver',
    appStorePassengerId: '6450717053',
    appStoreDriverId: '6450717110',
    email: "ismaelfelixmayorgacruz65@gmail.com",
};
export default config;
