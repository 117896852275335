//models
import {Sys} from "../../api/_sys/sysModel";
//types
export type SystemStoreDrawerFocus =
    "home"
    | "users"
    | "user_passenger"
    | "user_driver"
    | "user_admin"
    | "user_commerce"

    | "travel_urbans"
    | "travel_urban_reserve"
    | "travel_urban_dispatch"
    | "travel_urban_confirmed"
    | "travel_urban_road"
    | "travel_urban_completed"
    | "travel_urban_cancel_passenger"
    | "travel_urban_cancel_driver"

    | "channel"
    | "group"
    | "coupon"
    | "commerce"

    | "scope"
    | "scope_province"
    | "scope_city"

    | "vehicles"
    | "vehicle_brand"
    | "vehicle_color"
    | "vehicle_type"

    | "feedbacks"
    | "feedback_travel_urban"
    | "feedback_travel_interurban"
    | "feedback_sos"

    | "security"
    | "security_profile"
    | "security_role"
    | "security_schedule"

    | "profile"
    | "notification"

    | "sys"
    | "sys_default"
    | "sys_bank"

    | "service"

    | "balances"
    | "balance_user_driver"
    | "balance_user_passenger"
    | "";

export type SystemSnackbar = {
    open?: boolean;
    message: string;
    severity: "success" | "info" | "warning" | "error";
    duration?: number | undefined;
}

//interface
export interface SystemStoreState {
    token?: string;
    is_drawer?: boolean;
    drawer_open?: boolean;
    drawer_title?: string;
    drawer_subtitle?: string;
    drawer_focus?: SystemStoreDrawerFocus;
    snackbar?: SystemSnackbar;
    is_indicator_notification?: boolean;
    sys?: Sys;
}

export interface SystemStoreDispatch {
    postToken?: (token: string) => void;
    deleteToken?: () => void;
    putIsDrawer?: (is_drawer: boolean) => void;
    putDrawerTitle?: (drawer_title: string) => void;
    putDrawerSubtitle?: (drawer_subtitle: string) => void;
    putDrawerFocus?: (drawer_focus: SystemStoreDrawerFocus) => void;
    putDrawerOpen?: (drawer_open: boolean) => void;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
    putIsIndicatorNotification?: (is_indicator_notification: boolean) => void;
    putSys?: (sys: Sys) => void;
}

export interface SystemStoreAction extends SystemStoreState {
    type: "POST_TOKEN" | "DELETE_TOKEN" | "PUT_IS_DRAWER" | "PUT_DRAWER_TITLE" | "PUT_DRAWER_SUBTITLE" | "PUT_DRAWER_FOCUS" | "PUT_DRAWER_OPEN" | "SHOW_SNACKBAR" | "HIDE_SNACKBAR" | "PUT_IS_INDICATOR_NOTIFICATION" | "PUT_SYS"
}

//Inserta el token de sesión
export const postToken = (token: string): SystemStoreAction => ({
    type: "POST_TOKEN",
    token
});

//Elimina el token de sesión
export const deleteToken = (): SystemStoreAction => ({
    type: "DELETE_TOKEN",
});

//Habilita/Deshabilita el drawer
export const putIsDrawer = (is_drawer: boolean): SystemStoreAction => ({
    type: "PUT_IS_DRAWER",
    is_drawer
});

//Cambia el titulo del drawer
export const putDrawerTitle = (drawer_title: string): SystemStoreAction => ({
    type: "PUT_DRAWER_TITLE",
    drawer_title
});

//Cambia el subtitulo del drawer
export const putDrawerSubtitle = (drawer_subtitle: string): SystemStoreAction => ({
    type: "PUT_DRAWER_SUBTITLE",
    drawer_subtitle
});

//Cambia el subtitulo del drawer
export const putDrawerFocus = (drawer_focus: SystemStoreDrawerFocus): SystemStoreAction => ({
    type: "PUT_DRAWER_FOCUS",
    drawer_focus
});

//muestra/oculta el snackbar
export const putDrawerOpen = (drawer_open: boolean): SystemStoreAction => ({
    type: "PUT_DRAWER_OPEN",
    drawer_open
});

//muestra el snackbar
export const showSnackbar = (snackbar: SystemSnackbar): SystemStoreAction => ({
    type: "SHOW_SNACKBAR",
    snackbar: {
        ...snackbar,
        duration: snackbar.duration === undefined ? 5000 : snackbar.duration === 0 ? undefined : snackbar.duration,
    }
});

//ocultar el snackbar
export const hideSnackbar = (): SystemStoreAction => ({
    type: "HIDE_SNACKBAR",
});

//Habilita/Deshabilita el indicador de notificaciones
export const putIsIndicatorNotification = (is_indicator_notification: boolean): SystemStoreAction => ({
    type: "PUT_IS_INDICATOR_NOTIFICATION",
    is_indicator_notification
});

//Habilita/Deshabilita el indicador de notificaciones
export const putSys = (sys: Sys): SystemStoreAction => ({
    type: "PUT_SYS",
    sys
});
