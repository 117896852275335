//store
import reducers from './reducers';
//libs
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

const configureStore = () => {
    return createStore(reducers, applyMiddleware(thunk))
}

export default configureStore;
