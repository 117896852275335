//libs
import config from '../../config';
import ResolverFetch from "../../libs/_resolver_fetch";
import {getToken} from "../system/_token";
//model
import {TravelInterurbanUserResponse} from "./travelInterurbanUserModel";
//api
export const TravelInterurbanUserAPI = {
    getByCode: async (code: string) => {
        return await new Promise<TravelInterurbanUserResponse>(async (resolve: (value: TravelInterurbanUserResponse) => void, reject: (reason: TravelInterurbanUserResponse) => void) => {
            await ResolverFetch(`${config.host}/api/travel/interurban/user?type=getByCode&code=${code}`, {
                "method": "GET",
                "headers": {
                    "Accept": "application/json",
                    "authorization": await getToken()
                },
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    travel_interurban_user: (res.is_resolve && res.data) && res.data.travel_interurban_user,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error,
                });
            });
        });
    },
}
