//libs
import AsyncStorage from "@react-native-async-storage/async-storage";
import config from "../../config";
//types
//interface

//module
export const setToken = async (token: string) => {
    try {
        await AsyncStorage.setItem(`token::${config.package}`, token);
    } catch (error) {
        setTimeout(async () => await setToken(token), 1000);
    }
}

export const getToken = async (): Promise<string> => {
    let token: string = "";
    try {
        token = await AsyncStorage.getItem(`token::${config.package}`) || "";
    } catch (error) {
        token = "";
    }
    return token;
}
